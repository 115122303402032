<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" custom-class="custom-dialog-max-width" @closed="handleClosed">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-width="84px" label-suffix=":" label-position="right">
          <div class="h" v-if="form.orderFormId">
            <el-form-item label="经销商">{{form.buyerName}}</el-form-item>
            <el-form-item label="销售单号">{{form.orderFormCode}}</el-form-item>
          </div>
          <div class="h s" v-else>
            <el-form-item prop="buyerId" label="经销商">
              <quick-select v-model="form.buyerId" url="api/distributor" placeholder="经销商" filterable value-field="enterpriseId" style="width: 180px;" @change="handleDistributorChange" auto-select-first-option />
            </el-form-item>
            <el-form-item label-width="10px" v-if="form.buyerId">
              <sku-selector simple-query request-url="api/distributorGoods" :request-params="{distributorId: form.buyerId}" :exclude-keys="excludeGoods" @submit="handleGoodsAdd" />
            </el-form-item>
          </div>

          <el-form-item label="退货详情">
            <el-table :data="form.items" class="table-outdent">
              <el-table-column prop="code" label="商品编码" min-width="130" />
              <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
              <el-table-column label="商品" min-width="240">
                <div style="line-height: 1.5;" slot-scope="scope">
                  <div>{{scope.row.goodsName}}</div>
                  <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                </div>
              </el-table-column>
              <template v-if="form.orderFormId">
                <el-table-column key="rel_realPrice" prop="realPrice" label="单价" width="100" align="right" :formatter="$price" />
                <el-table-column key="rel_count" prop="count" label="销售数量" width="100" align="center" />
                <el-table-column key="rel_backCount" prop="_backCount" label="已退货数量" width="100" align="center" />
                <el-table-column key="rel_cancelCount" prop="_cancelCount" label="已取消数量" width="100" align="center" />
                <el-table-column key="rel_currentBackCount" label="本次退货" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row._currentBackCount" :min="0" :max="scope.row._maxBackCount" :step="1" :precision="2" size="mini" controls-position="right" @change="$checkNaN(scope.row, '_currentBackCount', 0)" style="width: 100%;" />
                  </template>
                </el-table-column>
              </template>
              <template v-else>
                <el-table-column key="no_realPrice" width="100" align="right">
                  <template slot="header" slot-scope="scope">
                    <div class="h c r lh-100">
                      <span>单价&nbsp;</span>
                      <el-tooltip content="该单价仅供参考，请以退货单审批后的单价为准！" placement="top">
                        <i class="fc-e el-icon-warning"></i>
                      </el-tooltip>
                    </div>
                  </template>
                  <template slot-scope="scope">{{$price(scope.row.realPrice)}}</template>
                </el-table-column>
                <el-table-column key="no_currentBackCount" label="本次退货" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row._currentBackCount" :min="0.01" :max="9999" :step="1" :precision="2" size="mini" style="width: 100%;" :controls="false" @change="$checkNaN(scope.row, '_currentBackCount', 1, loadGoodsPrice)" />
                  </template>
                </el-table-column>
              </template>
              <el-table-column prop="info" label="备注" width="150">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.info" :maxlength="100" />
                </template>
              </el-table-column>
              <template v-if="!form.orderFormId">
                <el-table-column width="50">
                  <div class="row-commands" slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleGoodsRemove(scope.row)">移除</el-button>
                  </div>
                </el-table-column>
              </template>
            </el-table>
          </el-form-item>

          <div class="h c">
            <el-form-item prop="senderName" label="发货人">
              <el-input v-model="form.senderName" :maxlength="50" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="senderPhone" label="联系电话" label-width="100px">
              <el-input v-model="form.senderPhone" :maxlength="25" style="width: 260px;" />
            </el-form-item>
            <el-form-item label-width="10px">
              <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/distributor/contact" :params="{distributorId: form.buyerId}" empty-text="该经销商没有常用收货人信息。" @change="handleContactChange">
                <el-table-column prop="name" label="姓名" width="100" />
                <el-table-column prop="mobilePhone" label="手机号码" width="150" />
                <el-table-column prop="officePhone" label="办公室电话" width="150" />
              </datagrid-picker>
            </el-form-item>
          </div>
          <div class="h c">
            <el-form-item label="发货地址" prop="sendProvinceCode">
              <quick-select v-model="form.sendProvinceCode" :label.sync="form.sendProvince" url="api/regionalDict/province" value-field="code" placeholder="选择发货省份" filterable style="width: 180px;" @change="form.sendCityCode = null; form.sendDistrictCode = null;" />
            </el-form-item>
            <el-form-item label-width="12px" prop="sendCityCode" style="width: 180px;">
              <quick-select v-model="form.sendCityCode" :label.sync="form.sendCity" :url="`api/regionalDict/parent/${form.sendProvinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.sendProvinceCode" style="width: 100%;" @change="form.sendDistrictCode = null" />
              <el-input disabled placeholder="请先选择发货城市" v-else />
            </el-form-item>
            <el-form-item label-width="12px" prop="sendDistrictCode" style="width: 180px;">
              <quick-select v-model="form.sendDistrictCode" :label.sync="form.sendDistrict" :url="`api/regionalDict/parent/${form.sendCityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.sendCityCode" style="width: 100%;" />
              <el-input disabled placeholder="请先选择发货地区" v-else />
            </el-form-item>
            <el-form-item label-width="12px" prop="sendAddress" class="flex">
              <el-input v-model="form.sendAddress" :maxlength="100" class="flex" placeholder="请输入详细地址" />
            </el-form-item>
            <el-form-item label-width="10px">
              <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/distributor/address" :params="{distributorId: form.buyerId}" empty-text="该经销商没有常用收货地址信息。" @change="handleAddressChange">
                <el-table-column prop="provinceName" label="省" width="100" />
                <el-table-column prop="cityName" label="市" width="150" />
                <el-table-column prop="districtName" label="区" width="150" />
                <el-table-column prop="address" label="详细地址" width="300" />
              </datagrid-picker>
            </el-form-item>
          </div>

          <el-form-item prop="info" label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="200" placeholder="有其他要求或需要说明的情况，请在备注中说明" resize="none" :rows="3" />
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" v-if="editable">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button :loading="saving" :disabled="submiting" type="primary" @click="doSave">保存</el-button>
          <el-button :loading="submiting" :disabled="saving" type="danger" @click="doSubmit" v-if="form.id">保存并提交</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" :rules="rules" label-width="76px" label-suffix="：" label-position="right">
          <div class="h sb">
            <div>
              <form-info-item label="经销商">{{form.buyerName}}</form-info-item>
              <form-info-item label="发货人">{{form.senderName}}（{{form.senderPhone}}）</form-info-item>
              <form-info-item label="发货地址">{{form.sendProvince}}{{form.sendCity}}{{form.sendDistrict}}{{form.sendAddress}}</form-info-item>
            </div>
            <div>
              <form-info-item label="退货单号">{{form.formCode}}</form-info-item>
              <form-info-item label="销售单号" v-if="form.orderFormId">{{form.orderFormCode}}</form-info-item>
              <form-info-item label="退货时间">{{new Date(form.createAt).format()}}</form-info-item>
              <form-info-item label="收货库房" v-if="form.isReceive">{{form.warehouseName}}</form-info-item>
            </div>
          </div>
          <el-table :data="backItems" border empty-text="当前退货单没有任何商品" style="margin: 8px 0;">
            <el-table-column prop="code" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column label="商品" min-width="240">
              <div style="line-height: 1.5;" slot-scope="scope">
                <div>{{scope.row.goodsName}}</div>
                <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
              </div>
            </el-table-column>
            <el-table-column width="150" align="right">
              <template slot="header" slot-scope="scope">
                <div class="h c r lh-100">
                  <span>单价&nbsp;</span>
                  <el-tooltip content="该单价仅供参考，请以退货单审批后的单价为准！" placement="top" v-if="!form.orderFormId && action !== 'verify'">
                    <i class="fc-e el-icon-warning"></i>
                  </el-tooltip>
                </div>
              </template>
              <template slot-scope="scope">
                <price-input v-model="scope.row.realPrice" :min="0" :controls="false" style="width: 100%" v-if="!form.orderFormId && action === 'verify'" />
                <span v-else>{{$price(scope.row.realPrice)}}</span>
              </template>
            </el-table-column>
            <template v-if="form.orderFormId">
              <el-table-column prop="count" label="销售数量" width="100" align="center" />
              <el-table-column prop="_backCount" label="已退货数量" width="100" align="center" />
              <el-table-column prop="_cancelCount" label="已取消数量" width="100" align="center" />
            </template>
            <el-table-column prop="_currentBackCount" label="本次退货" width="100" align="center" />
            <el-table-column label="备注" min-width="150" v-if="action === 'verify'">
              <template slot-scope="scope">
                <el-input v-model="scope.row.info" :max="100" />
              </template>
            </el-table-column>
            <el-table-column prop="info" label="备注" min-width="150" v-else />
          </el-table>
          <div class="h s">
            <form-info-item class="flex" label="备注">{{form.info || "无"}}</form-info-item>
            <form-info-item label="应退现金" label-width="100px">
              <b v-if="form.orderFormId">{{$price(form.totalMoney || 0)}}</b>
              <b v-else>{{$price(totalMoney || 0)}}</b>
            </form-info-item>
            <form-info-item label="应退返利金" label-width="114px" v-if="form.rebateAmount">
              <b>{{$price(form.rebateAmount)}}</b>
            </form-info-item>
            <form-info-item label="实退现金" label-width="100px" v-if="form.realBackMoney">
              <b>{{$price(form.realBackMoney)}}</b>
            </form-info-item>
          </div>
          <div class="h c">
            <form-info-item label="处理方式">
              <div style="width: 180px;">{{backTypes[form.backType]}}</div>
            </form-info-item>
            <form-info-item label="当前状态" label-width="100px">
              <dot v-if="form.backType==='fix'" same :type="repairStatus[form.status].type">{{repairStatus[form.status].label}}</dot>
              <dot v-else same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
          </div>
          <div class="h c" v-if="form.status > 1">
            <form-info-item label="受理人">
              <div style="width: 180px;">{{form.serviceName}}</div>
            </form-info-item>
            <form-info-item label="受理时间" label-width="100px">
              <div style="width: 180px;">{{new Date(form.serviceCheckTime).format()}}</div>
            </form-info-item>
            <form-info-item label="受理意见" class="flex" label-width="100px">{{form.advice || "无"}}</form-info-item>
          </div>
          <div class="h c" v-if="form.status >= 3">
            <form-info-item label="审批人">
              <div style="width: 180px;">{{form.checkerName}}</div>
            </form-info-item>
            <form-info-item label="审批时间" label-width="100px">
              <div style="width: 180px;">{{new Date(form.checkTime).format()}}</div>
            </form-info-item>
            <form-info-item label="审批意见" class="flex" label-width="100px">{{form.checkInfo || "无"}}</form-info-item>
          </div>
        </el-form>
        <template v-if="action === 'handle' && form.status === 1">
          <el-divider content-position="left">受理</el-divider>
          <el-form ref="auditForm" :rules="rules" label-width="80px" label-position="top">
            <div class="h e">
              <el-form-item label="是否受理">
                <el-radio-group v-model="auditForm.pass">
                  <el-radio-button :label="true">同意</el-radio-button>
                  <el-radio-button :label="false">拒绝</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="受理意见" class="flex" style="margin-left: 20px;">
                <el-input v-model="auditForm.advice" :maxlength="200" />
              </el-form-item>
              <el-form-item style="margin-left: 20px;">
                <el-button type="primary" :loading="submiting" @click="doHandle">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </template>
        <!-- <el-form ref="form" :model="form" :rules="rules" label-width="76px" label-suffix="：" label-position="right" v-else>
              <form-info-item label="处理方式">{{backTypes[form.backType]}}</form-info-item>
              <form-info-item label="费用清单" content-style="padding: 0">
                <costs :order-id="form.id" :editable="false" style="margin-top: -3px;" />
              </form-info-item>
              <form-info-item label="处理意见" v-if="form.status !== 4">{{form.advice || "无"}}</form-info-item>
        </el-form>-->
        <template v-if="action === 'refund' && !form.isRefund && form.backType === 'returnMoney' && form.status === 4">
          <el-divider content-position="left">退款</el-divider>
          <el-form ref="refundForm" :model="refundForm" :rules="refundRules" label-position="top">
            <div class="h e">
              <el-form-item label="退款日期" prop="backDatetime">
                <el-date-picker type="date" v-model="refundForm.backDatetime" value-format="timestamp" :clearable="false" style="width: 200px" />
              </el-form-item>
              <el-form-item label="实际退款金额" prop="totalMoney" style="margin-left: 20px;">
                <price-input v-model="refundForm.totalMoney" :min="0" :max="form.totalMoney / 100" style="width: 200px" />
              </el-form-item>
              <el-form-item style="margin-left: 20px;">
                <el-button type="primary" :loading="submiting" @click="doRefund">确认退款</el-button>
              </el-form-item>
              <el-form-item style="margin-left: 10px;">
                <el-button @click="dialog = false">取消</el-button>
              </el-form-item>
            </div>
          </el-form>
        </template>
        <template v-else-if="action === 'inbound' && !form.isReceive && form.status === 2">
          <el-divider content-position="left">入库</el-divider>
          <el-form :model="inboundForm" :rules="inboundRules" label-width="76px" label-suffix=":" label-position="right" hide-required-asterisk>
            <div class="h">
              <el-form-item prop="warehouseId" label="入库库房">
                <quick-select v-model="inboundForm.warehouseId" filterable url="api/warehouse" auto-select-first-option placeholder="请选择入库库房" style="width: 240px;" />
              </el-form-item>
              <el-form-item label-width="10px">
                <el-button type="primary" :loading="submiting" @click="doInbound">确认入库</el-button>
              </el-form-item>
            </div>
          </el-form>
        </template>
        <template v-else-if="action === 'verify' && form.status === 2">
          <el-divider content-position="left">审批</el-divider>
          <el-form :model="verifyForm" :rules="verifyRules" label-width="76px" label-position="top" hide-required-asterisk>
            <div class="h e">
              <el-form-item label="处理方式">
                <el-radio-group v-model="verifyForm.backType">
                  <el-radio-button v-for="(o, k) in backTypes" :key="k" :label="k">{{o}}</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="warehouseId" label="入库库房" style="margin-left: 20px;">
                <quick-select v-model="verifyForm.warehouseId" url="api/warehouse" placeholder="选择入库库房" auto-select-first-option filterable style="width: 180px;" />
              </el-form-item>
              <el-form-item label="审批意见" class="flex" style="margin-left: 20px;">
                <el-input v-model="verifyForm.checkInfo" :maxlength="200" />
              </el-form-item>
              <el-form-item style="margin-left: 20px;">
                <el-button type="primary" :loading="submiting" @click="doVerify">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </template>

        <template v-else-if="action === 'finish' && form.status === 4">
          <el-divider content-position="left">完成</el-divider>
          <el-form :model="form" label-width="76px" label-suffix=":" label-position="right" hide-required-asterisk>
            <div class="h">
              <el-form-item label="处理意见" class="flex">
                <el-input v-model="form.advice" :maxlength="200" />
              </el-form-item>
              <el-form-item label-width="10px">
                <el-button :loading="submiting" type="primary" @click="doFinish">完成退货</el-button>
              </el-form-item>
            </div>
          </el-form>
        </template>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import {
  add,
  get,
  edit,
  submit,
  stockIn,
  refund,
  saveHandle,
  submitHandle,
  verify,
  finish
} from "@/api/orderBackForm";
import checkPermission from "@/utils/permission";
import { getDistributorGoodsPrice } from "@/api/distributorOrder";
import { getDefaultAddress, getDefaultContact } from "@/api/receiving";
import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";

export default {
  components: { skuSelector },
  data() {
    return {
      loading: false,
      saving: false,
      submiting: false,
      dialog: false,
      action: null,
      form: null,
      backTypes: {
        returnMoney: "退货退款",
        fix: "维修"
      },
      // 状态与操作  编辑中 审批未通过 、待审批 审批通过 已关闭
      repairStatus: [
        {
          type: "info",
          label: "编辑中"
        },
        {
          type: "warning",
          label: "待受理"
        },
        {
          type: "success",
          label: "已同意"
        },
        {
          type: "danger",
          label: "已拒绝"
        },
        {
          type: "success",
          label: "处理中"
        },
        {
          type: "primary",
          label: "维修已发出"
        },
        {
          type: "primary",
          label: "维修部分发出"
        },
        {
          type: "primary",
          label: "厂家已收货"
        }
      ],
      status: [
        {
          type: "info",
          label: "编辑中"
        },
        {
          type: "warning",
          label: "待受理"
        },
        {
          type: "success",
          label: "已同意"
        },
        {
          type: "danger",
          label: "已拒绝"
        },
        {
          type: "success",
          label: "处理中"
        },
        {
          type: "primary",
          label: "已完成"
        }
      ],
      rules: {
        buyerId: [{ required: true, message: "请选择经销商" }],
        sendProvinceCode: [{ required: true, message: "请选择省份" }],
        sendCityCode: [{ required: true, message: "请选择城市" }],
        sendDistrictCode: [{ required: true, message: "请选择地区" }],
        sendAddress: [{ required: true, message: "请填写详细地址" }],
        senderName: [{ required: true, message: "请填写发货人" }],
        senderPhone: [{ required: true, message: "请填写发货人联系电话" }]
      },
      auditForm: {
        id: null,
        pass: true,
        advice: ""
      },
      inboundForm: {
        id: null,
        warehouseId: null
      },
      inboundRules: {
        warehouseId: [{ required: true, message: "请选择入库库房" }]
      },
      verifyForm: {
        id: null,
        warehouseId: null,
        backType: "fix",
        checkInfo: ""
      },
      verifyRules: {
        warehouseId: [{ required: true, message: "请选择入库库房" }]
      },
      refundForm: {
        id: null,
        backDatetime: new Date().getTime(),
        payChannel: 2,
        totalMoney: 0
      },
      refundRules: {
        backDatetime: [{ required: true, message: "请选择退货日期" }],
        totalMoney: [{ required: true, message: "请填写实际退款金额" }]
      }
    };
  },
  computed: {
    title() {
      let str = "加载中…";
      if (this.form) {
        return this.editable
          ? this.form.id
            ? "编辑退货单"
            : "新建退货单"
          : "退货单详情";
      }
      return str;
    },
    totalMoney() {
      let m = 0;
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach(o => {
          m += o.realPrice * o._currentBackCount;
        });
      }
      return m;
    },
    editable() {
      return (
        this.checkPermission(["RETURNMANAGE_ALL", "RETURNMANAGE_EDIT"]) &&
        this.form &&
        this.form.status === 0
      );
    },
    excludeGoods() {
      return (this.form.items || []).map(o => {
        return o.goodsId;
      });
    },
    backItems() {
      if (this.form && this.form.items && this.form.items.length) {
        return this.form.items.filter(o => {
          return o._currentBackCount > 0;
        });
      }
      return [];
    }
  },

  methods: {
    checkPermission,
    handleClosed() {
      this.form = null;

      this.auditForm.id = null;
      this.auditForm.pass = true;
      this.auditForm.advice = "";

      this.refundForm.id = null;
      this.inboundForm.id = null;

      this.verifyForm.id = null;
      this.verifyForm.warehouseId = null;
      this.verifyForm.checkInfo = "";
      this.tab = "base";
    },
    convertForm() {
      let form = null,
        items = [],
        count = 0;
      (this.form.items || []).forEach(o => {
        if (!o._currentBackCount) o._currentBackCount = 0;
        if (o._currentBackCount > 0) {
          count += o._currentBackCount;
          items.push({
            orderFormId: o.orderFormId,
            orderFormCode: o.orderFormCode,
            orderFormItemId: o.orderFormItemId,
            goodsId: o.goodsId,
            realPrice: o.realPrice,
            count: o._currentBackCount,
            info: o.info
          });
        }
      });
      if (count === 0) {
        this.$alert("请填写要退货的数量。", "系统提示");
      } else {
        form = JSON.parse(JSON.stringify(this.form));
        form.items = items;
      }
      return form;
    },
    doSave(callback, refresh = true) {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          let form = this.convertForm();
          if (form) {
            this.saving = true;
            (this.form.id ? edit : add)(form)
              .then(res => {
                if (res) this.form.id = res.id;
                if (refresh) this.$parent.init();
                this.saving = false;
                if (typeof callback === "function") callback();
                else
                  this.$notify({
                    title: "保存销售退货单成功",
                    type: "success",
                    duration: 2500
                  });
              })
              .finally(_ => {
                this.saving = false;
              });
          }
        });
    },
    doSubmit() {
      if (this.form && this.form.id) {
        this.$confirm(
          "提交退货单后将不能再进行修改，确定要提交吗？",
          "操作确认",
          {
            type: "warning",
            dangerouslyUseHTMLString: true
          }
        ).then(res => {
          this.doSave(_ => {
            this.submiting = true;
            submit({ id: this.form.id })
              .then(res => {
                this.$notify({
                  title: "提交退货单成功",
                  type: "success",
                  duration: 2500
                });
                this.dialog = false;
                this.$parent.init();
              })
              .finally(_ => {
                this.submiting = false;
              });
          });
        });
      }
    },
    doHandle() {
      this.$confirm(
        `确定要【${this.auditForm.pass ? "同意" : "拒绝"}】当前退货单吗？`,
        "操作确认",
        {
          type: "warning",
          dangerouslyUseHTMLString: true
        }
      ).then(res => {
        this.submiting = true;
        submitHandle(this.auditForm)
          .then(res => {
            this.$notify({
              title: (this.auditForm.pass ? "同意" : "拒绝") + "退货单成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.submiting = false;
          });
      });
    },
    doInbound() {
      this.submiting = true;
      stockIn(this.inboundForm)
        .then(res => {
          this.$notify({
            title: "确认入库成功",
            type: "success",
            duration: 2500
          });
          this.dialog = false;
          this.$parent.init();
        })
        .finally(_ => {
          this.submiting = false;
        });
    },
    doVerify() {
      this.$confirm("确定要核验当前退货单吗？", "操作确认", {
        type: "warning",
        dangerouslyUseHTMLString: true
      }).then(res => {
        this.submiting = true;
        let ds = JSON.parse(JSON.stringify(this.verifyForm));
        if (!this.form.orderFormId) {
          ds.items = this.form.items.map(o => {
            return {
              id: o.itemId,
              count: o.currentBackCount,
              realPrice: o.realPrice,
              info: o.info
            };
          });
        }
        verify(ds)
          .then(res => {
            this.$notify({
              title: "确认核验成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.submiting = false;
          });
      });
    },
    doRefund() {
      this.$refs.refundForm.validate().then(_ => {
        this.submiting = true;
        refund(this.refundForm)
          .then(res => {
            this.$notify({
              title: "退款成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.submiting = false;
          });
      });
    },
    doFinish() {
      this.submiting = true;
      finish({
        id: this.form.id,
        advice: this.form.advice
      })
        .then(res => {
          this.$notify({
            title: "完成退货成功",
            type: "success",
            duration: 2500
          });
          this.dialog = false;
          this.$parent.init();
        })
        .finally(_ => {
          this.submiting = false;
        });
    },
    resetForm(form = null, order = null, action = null) {
      let ownId = null,
        sourceId = null;
      if (form) {
        ownId = form.id;
        sourceId = form.orderFormId;
        this.auditForm.id = ownId;
        this.refundForm.id = ownId;
        this.refundForm.totalMoney = form.totalMoney;
        this.verifyForm.id = ownId;
        this.inboundForm.id = ownId;
      } else if (order) {
        sourceId = order.id;
      }
      this.action = action;
      if (sourceId) {
        this.loading = true;
        request({
          url: "api/orderBackForm/orderForm",
          method: "post",
          data: {
            orderFormId: sourceId,
            id: ownId
          }
        })
          .then(res => {
            res.items = (res.backItems || []).map(o => {
              let _no = {
                id: this.$uuid(),
                orderFormId: sourceId,
                orderFormCode: order ? order.formCode : form.orderFormCode,
                orderFormItemId: o.id,
                code: o.code,
                barcode: o.barcode,
                erpCode: o.erpCode,
                goodsId: o.goodsId,
                goodsSpec: o.goodsSpec,
                goodsName: o.goodsName,
                goodsBrandId: o.goodsBrandId,
                goodsBrandName: o.goodsBrand,
                goodsSeriesId: o.goodsSeriesId,
                goodsSeriesName: o.goodsSeries,
                goodsCategoryId: o.goodsCategoryId,
                goodsCategoryName: o.goodsCategoryName,
                price: o.price,
                realPrice: o.realPrice,
                count: o.count || 0,
                info: "",
                _backCount: o.backCount || 0,
                _currentBackCount: o.currentBackCount || 0,
                _cancelCount: o.cancelCount || 0
              };
              _no._maxBackCount = _no.count - _no._backCount - _no._cancelCount;
              if (_no._maxBackCount < 0) _no._maxBackCount = 0;
              return _no;
            });
            delete res.backItems;
            if (order) {
              Object.assign(res, {
                buyerId: order.buyerId,
                buyerName: order.buyerName,
                sellerId: order.sellerId,
                sellerName: order.sellerName,
                orderFormId: order.id,
                orderFormCode: order.formCode,
                senderName: order.receiverName,
                senderPhone: order.receiverPhone,
                sendProvinceCode: order.recProvinceCode,
                sendProvince: order.recProvince,
                sendCityCode: order.recCityCode,
                sendCity: order.recCity,
                sendDistrictCode: order.recDistrictCode,
                sendDistrict: order.recDistrict,
                sendAddress: order.recAddress,
                status: 0,
                info: ""
              });
            }

            if (!res.backType) res.backType = "returnMoney";

            this.form = res;
          })
          .finally(_ => {
            this.loading = false;
          });
      } else {
        if (form && form.id) {
          this.loading = true;
          request({
            url: "api/orderBackForm/getById",
            method: "post",
            data: {
              id: form.id
            }
          })
            .then(res => {
              (res.backItems || []).forEach(o => {
                o._currentBackCount = o.currentBackCount;
              });
              res.items = res.backItems;
              if (!res.backType) res.backType = "returnMoney";
              delete res.backItems;
              this.form = res;
            })
            .finally(_ => {
              this.loading = false;
            });
        } else {
          this.form = {
            buyerId: null,
            buyerName: null,
            orderFormId: null,
            orderFormCode: null,
            senderName: null,
            senderPhone: null,
            sendProvinceCode: null,
            sendProvince: null,
            sendCityCode: null,
            sendCity: null,
            sendDistrictCode: null,
            sendDistrict: null,
            sendAddress: null,
            status: 0,
            advice: "",
            info: "",
            items: []
          };
        }
      }
      this.dialog = true;
    },
    loadGoodsPrice(g) {
      if (g && g.goodsId && !isNaN(g.count)) {
        g.realPrice = null;
        getDistributorGoodsPrice({
          goodsId: g.goodsId,
          quantity: g.count,
          buyerId: this.form.buyerId
        })
          .then(res => {
            g.realPrice = res.goodsPrice;
          })
          .catch(err => {
            g.realPrice = null;
          });
      }
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        this.form.items = (this.form.items || []).concat(
          list.map(o => {
            let _no = {
              id: this.$uuid(),
              orderFormId: null,
              orderFormCode: null,
              orderFormItemId: null,
              code: o.code,
              barcode: o.barcode,
              erpCode: o.erpCode,
              goodsId: o.id,
              goodsSpec: o.specs,
              goodsName: o.name,
              goodsBrandId: o.brandId,
              goodsBrandName: o.brandName,
              goodsSeriesId: o.seriesId,
              goodsSeriesName: o.seriesName,
              goodsCategoryId: o.categoryId,
              goodsCategoryName: o.categoryName,
              realPrice: 0,
              info: "",
              _currentBackCount: 1
            };
            this.loadGoodsPrice(_no);
            return _no;
          })
        );
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      let inx = this.form.items.indexOf(row);
      if (inx >= 0) {
        this.form.items.splice(inx, 1);
      }
    },

    handleDistributorChange() {
      Object.assign(this.form, {
        senderName: null,
        senderPhone: null,
        sendProvinceCode: null,
        sendProvince: null,
        sendCityCode: null,
        sendCity: null,
        sendDistrictCode: null,
        sendDistrict: null,
        sendAddress: null,
        items: []
      });
      getDefaultContact(this.form.buyerId).then(res => {
        if (res) this.handleContactChange(null, null, res);
      });
      getDefaultAddress(this.form.buyerId).then(res => {
        if (res) this.handleAddressChange(null, null, res);
      });
    },
    handleContactChange(ov, nv, row) {
      if (row) {
        let phones = [];
        if (row.mobilePhone) phones.push(row.mobilePhone);
        if (row.officePhone) phones.push(row.officePhone);

        this.form.senderName = row.name;
        this.form.senderPhone = phones.join(", ");
      }
    },
    handleAddressChange(ov, nv, row) {
      if (row) {
        Object.assign(this.form, {
          sendProvinceCode: row.provinceCode,
          sendProvince: row.provinceName,
          sendCityCode: row.cityCode,
          sendCity: row.cityName,
          sendDistrictCode: row.districtCode,
          sendDistrict: row.districtName,
          sendAddress: row.address
        });
      }
    }
  }
};
</script>
