import request from '@/utils/request'


export function add (data) {
  return request({
    url: 'api/orderBackForm',
    method: 'post',
    data
  })
}
export function verify (data) {
  return request({
    url: 'api/orderBackForm/check',
    method: 'post',
    data
  })
}
export function submit (data) {
  return request({
    url: 'api/orderBackForm/submit',
    method: 'post',
    data
  })
}
export function get (id) {
  return request({
    url: "api/orderBackForm/" + id,
    method: "get"
  });
}

export function edit (data) {
  return request({
    url: 'api/orderBackForm',
    method: 'put',
    data
  })
}

export function del (id) {
  return request({
    url: 'api/orderBackForm/' + id,
    method: 'delete'
  })
}

export function stockIn (data) {
  return request({
    url: 'api/orderBackForm/stockInForErp',
    method: 'post',
    data
  })
}

export function refund (data) {
  return request({
    url: 'api/orderBackForm/refund',
    method: 'post',
    data
  })
}


export function saveHandle (data) {
  return request({
    url: 'api/orderBackForm/serviceEdit',
    method: 'post',
    data
  })
}


export function submitHandle (data) {
  return request({
    url: 'api/orderBackForm/serviceCheck',
    method: 'post',
    data
  })
}

export function finish (data) {
  return request({
    url: 'api/orderBackForm/finish',
    method: 'post',
    data
  })
}
